@use "sass:map";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";

// Import Bootstrap's SCSS files
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";


// Change the default Bootstrap colors
$primary: #015162;
$secondary: #949494;
$success: #7da733;
$dark: #000202;
$light: #fff;
$theme-colors: map.merge($theme-colors, (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "dark": $dark,
  "light": $light
));

// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";
