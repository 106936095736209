@keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-50%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .slideIn {
    animation: slideIn 1s ease-in-out;
  }

@keyframes slideOut {
    from {
      opacity: 0;
      transform: translateY(0);
    }
    to {
      opacity: 1;
      transform: translateY(-50%);
    }
  }

  .slideOut {
    animation: slideOut 1s ease-in-out;
  }