/* Nav Tabs  */

.nav.nav-pills .nav-link {
    background: #fafafa !important;
    color: #7b7b7b !important;
    border-bottom: 4px solid #fafafa !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
}

.nav.nav-pills li.nav-item {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
}

.nav.nav-pills .nav-link.active {
    background: #fafafa !important;
    color: var(--bs-success) !important;
    border-bottom: 4px solid var(--bs-success) !important;
    border-radius: 0 !important;
    font-weight: 800 !important;
}

.tab-dropdown {
    background: #fafafa !important;
}
